import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout';
import Navbar from './components/Navbar';
import Home from "./components/Home";

import brand from './assets/brand.png';
import './App.css';

function App() {
  return (
    <div className="layout-background flex items-center justify-center min-h-screen">
    <Router>
      <Layout>
        <div className="centered-content-container">
          <img src={brand} alt="Brand" className="brand" />
        </div>
        <div className="centered-content-container">
          <Navbar />
        </div>
        <br />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Layout>
    </Router>
    </div>
  );
}

export default App;
