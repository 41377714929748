import React from 'react';

import SelfDevelopment from './products/SelfDevelopment';
import Fitness from './products/Fitness';
import TimeManagement from './products/TimeManagement';

interface ProductsProps {
}

const Products: React.FC<ProductsProps> = () => {
  return (
    <div >
      <SelfDevelopment />
      <br/>
      <TimeManagement />
      <br/>
      <Fitness />
    </div>
  );
};

export default Products;