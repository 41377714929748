import React from 'react';

import Products from './Products';
import ShowText from './ShowText';
import Resource from './Resource';
import About from "./About";

import brand from './../assets/brand.png';
import landing from './../assets/landing.png';

const Home: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
          <div className="content">
            <ShowText color="black" size="3.5" family="Raleway" text="Design your Rich Life" />
            <br/>
            <ShowText color="black" size="2" family="Montserrat" text="Whether you want to improve your time management, 
            personal finances, business or health habits, here I want to share with you content and products
            that can help you in your journey of designing your rich life." />
          </div>
          <div className="content">
            <img src={landing} alt="LandingPageImage" />
          </div>
        </div>
        <br/><br/>
        <About />
        <br/><br/>
        <Resource />
        <br/><br/>
        <Products/>
        <br/><br/>
        <br/><br/>
        <img src={brand} alt="Brand" className="max-w-sm" />
        <br/><br/>
    </div>
  );
};

export default Home;