import React from 'react';
import ShowText from './ShowText';
import { Link } from 'react-router-dom';
import logo from './../assets/logo.png';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar-container">
      <div className="logo">
        <img src={logo} alt="Business Logo" className="logo-image" />
      </div>
      <div className="nav-links">
        <Link to="/"><ShowText color="black" size="1.5" family="Montserrat" text="Home" /></Link>
        <a href="#about" className="text-gray-700 hover:text-gray-900"><ShowText color="black" size="1.5" family="Montserrat" text="About" /></a>
        <a href="#resource" className="text-gray-700 hover:text-gray-900"><ShowText color="black" size="1.5" family="Montserrat" text="Resource" /></a>
      </div>
    </nav>
  );
};

export default Navbar;
