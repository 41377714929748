import React from 'react';

import ShowText from './../ShowText';
import ProfileCard from './../ProfileCard';

import img from './../../assets/self_development.png';
import heartImg from './../../assets/heart.png'
import bookImg from './../../assets/book.png';
import journalImg from './../../assets/journal.png';

const SelfDevelopment: React.FC = () => {
  return (
    <div>
        <div className='content-container'>
            <div className="foreground-container">
                <div className="content">
                    <ShowText color="black" size="3" family="Raleway" text="Self-Development & Life Improvement" />
                    <br/>
                    <ShowText color="black" size="1.5" family="Montserrat" text="
                    Absorb what is useful. Discard what is not. Add what is uniquely your own.—Bruce Lee
                    "/>
                </div>
                <div className="content">
                    <img src={img} alt="Img" className="w-70 h-70 object-cover mb-4" />
                </div>
            </div>
            <div className="flex flex-wrap gap-20 p-6">
                <ProfileCard 
                imageSrc={bookImg}
                title="Atomic Habits"
                description="Get the best products and save money with Amazon"
                buyLink="https://amzn.to/40UFyqD"
                />
                <ProfileCard 
                imageSrc={bookImg}
                title="Think and Grow Rich"
                description="Get the best products and save money with Amazon"
                buyLink="https://amzn.to/3YVWZ8J"
                />
                <ProfileCard 
                imageSrc={journalImg}
                title="The Five Minute Journal"
                description="Get the best products and save money with Amazon"
                buyLink="https://amzn.to/4hHzSWT"
                />
                <ProfileCard 
                imageSrc={heartImg}
                title="Vision Board Kit"
                description="Get the best products and save money with Amazon"
                buyLink="https://amzn.to/3NXbORZ"
                />
                <ProfileCard 
                imageSrc={bookImg}
                title="The Power of Now"
                description="Get the best products and save money with Amazon"
                buyLink="https://amzn.to/4fceR4R"
                />
                <ProfileCard 
                imageSrc={bookImg}
                title="How to Win Friends & Influence People"
                description="Get the best products and save money with Amazon"
                buyLink="https://amzn.to/3Z0mIgk"
                />
            </div>
        </div>
    </div>
  );
};

export default SelfDevelopment;